import * as React from "react"
import { graphql } from 'gatsby';

// Plugins
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image'

// Application
import Layout from '../shell/layout'
import MadeInCanada from '../components/made-in-canada'

// Styles
import { indexPage, divider, intro, introTextWrapper, presentation, madeInCanadaWrapper } from '../styles/index.module.css'

// Markup
const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <div className={indexPage}>
          <StaticImage
            src="../images/start-your-engine.jpg"
            alt="Start your engine"
            placeholder="none"
          />

          <div className={divider}></div>

          {/* Introduction */}
          <section className={intro}>
            <StaticImage
              src="../images/le-depassement-commence-ici.jpg"
              alt="Le dépassement commence ici"
              placeholder="none"
            />
            <div className={introTextWrapper}>
              <h1>{t("home-intro-title").toUpperCase()}</h1>
              <p>{t("home-intro-p1")}</p>
              <p>{t("home-intro-p2")}</p>
              <p>{t("home-intro-p3")}</p>
            </div>
          </section>

          <div className={divider}></div>

          <section className={presentation}>
            <div className={madeInCanadaWrapper}>
              <MadeInCanada />
            </div>
            <p>{t("home-description-p1")}</p>
            <p>{t("home-description-p2")}</p>
          </section>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;